import React from "react"; 
import Layout from "../components/Layout";

const pageStyles = {
    color: "#FFF",
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center", 
    height: "100%"
}

const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
}
  
const paragraphStyles = {
    marginBottom: 48,
}

const codeStyles = {
    color: "#8A6534",
    padding: 4,
    backgroundColor: "#FFF4DB",
    fontSize: "1.25rem",
    borderRadius: 4,
}

const Loading = () => {
    return (
        <Layout>
            <main style={pageStyles}>
                <div className = "loading"></div>
            </main>
        </Layout>
    )
}

export default Loading;